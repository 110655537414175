import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader'
import router from './router/index.js'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import commonMixin from '@/common/CommonMixin'
import UserMixin from '@/common/UserMixin'
import { store } from "@/store/store";
import mitt from 'mitt'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// const peerObject = {
//    host: process.env.VUE_APP_PEER_URL,
//    port: process.env.VUE_APP_PEER_PORT,
//    // secure:process.env.VUE_APP_PEER_SECURE
//      }
    

const emitter = mitt()
loadFonts()
const app =  createApp(App)
    .mixin(commonMixin)
    .mixin(UserMixin)
    .use(vuetify).use(store)
    .use(VueSweetalert2)
    .use(router);
app.component('VueDatePicker', VueDatePicker);
// app.provide('peerObject',peerObject);
app.config.globalProperties.emitter = emitter
app.mount('#app')