<template>
  <v-app class="pro-wrapper">
    <v-main>
  
      <router-view />
  
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
   
  },

  data: () => ({
    
  }),
}
</script>
