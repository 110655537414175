import axios from "axios";
const APP_URL = process.env.VUE_APP_ROOT_URL;
const baseURL = `${APP_URL}/api/`;

const headers = {
  "Content-Type": "application/json",
  withCredentials: true,
};
export const HTTP = axios.create({
  baseURL,
  headers: headers,
});
HTTP.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("accesstoken") ||
      localStorage.getItem("clientaccesstoken") ||
      localStorage.getItem("dmvaccesstoken") ||
      localStorage.getItem("customeraccesstoken") ||
      localStorage.getItem("educationalaccesstoken") ||
      localStorage.getItem("studentaccesstoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
