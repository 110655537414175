import { createStore } from "vuex";
export const store = createStore({
  state: {
    user:{},
    user_name:localStorage.getItem('user_name'),
    email: localStorage.getItem('email'),
    eduAutoDeskscreen: null,
    custMessage: '',
    Proctor_Id: '',
    Customer_loc: '',
    customer_Blinker: false,
    showCancelAlert : false,
    showPauseAlert : false,  
    showPauseAutoAlert: false,
    stopAnomilies: false,  
    videoShareEduProcWeb:null,
    screenShareEduProcWeb: null,
    showTheSkeletonTop: true
  },
  getters: {},

  mutations: {
    setCustMessage(state, message) {
      state.custMessage = message
      state.customer_Blinker = true
    },

    setProctorId(state, message) {
      state.Proctor_Id = message
    },

    setCustomerloc(state, message) {
      state.Customer_loc = message
    },

    set_stopAnomilies(state,value){
      state.stopAnomilies = value
    }
  },
  actions: {},
});
