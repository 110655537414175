import { HTTP } from "@/common/common-http";
export default {
  methods: {
    triggerToast(title, status, text) {
      // toast alert
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        // confirmButtonText: "View",
        showCloseButton: true,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: `${status}`,
        title: `${title}`,
        text: `${text}`,
      });
    },
    endExamSession() {
      this.service_to_destiny();
      this.$store.state.station_cust_exam_id = "";
      localStorage.removeItem("station_cust_exam_id");
      this.$store.state.station_exams_id = "";
      localStorage.removeItem("station_exams_id");
      this.$store.state.station_lang_id = "";
      localStorage.removeItem("station_lang_id");
    },

    service_to_destiny(exams_id = "") {
        // console.log('destiny service', exams_id)
        HTTP.post(`kts/to-destiny/${exams_id ? exams_id : this.station_exams_id}`)
          .then((response) => {
            const data = response.data.data;
            console.log('exam_canceled', data)
          })
          .catch((error) => {
            console.error(error);
          });
      },

      utcToLocalProctor(utcTimeStamp){
        const dateInUTC = new Date(utcTimeStamp);

        // Convert UTC timestamp to local time
        const localYear = dateInUTC.getFullYear();
        const localMonth = dateInUTC.getMonth() + 1; // Month is zero-indexed, so add 1
        const localDay = dateInUTC.getDate();
        const localHours = dateInUTC.getHours();
        const localMinutes = dateInUTC.getMinutes();
        const localSeconds = dateInUTC.getSeconds();

        // Construct the local time string
        const localTimeString = `${localMonth}/${localDay}/${localYear}, ${localHours}:${localMinutes}:${localSeconds}`;
        console.log(utcTimeStamp,localTimeString); //

        return localTimeString;

    },


  utcToLocal(dateString) {
    const [datePart, timePart] = dateString.split(', ');
    const [month, day, year] = datePart.split('/');
    const [time, ampm] = timePart.split(' ');
    const [hours, minutes, seconds] = time.split(':');

    let hour = parseInt(hours);
    if (ampm === 'PM' && hour < 12) {
        hour += 12;
    } else if (ampm === 'AM' && hour === 12) {
        hour = 0;
    }

    let fullYear = parseInt(year);
    if (fullYear < 100) {
        // Convert two-digit year to four-digit year
        const currentYear = new Date().getFullYear();
        const centuryPrefix = currentYear - (currentYear % 100); // Get the century prefix
        fullYear += centuryPrefix;
    }

    const utcDate = new Date(Date.UTC(fullYear, month - 1, day, hour, minutes, seconds));
    const localDateString = utcDate.toLocaleString();

    return localDateString;
}

  
  },
};
