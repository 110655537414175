export default {
  computed: {
    user_location_nav() {
      return (
        this.$store.state.user_location_Nav ||
        localStorage.getItem("user_location_Nav")
      );
    },
    user_name() {
      return this.$store.state.user_name || localStorage.getItem("user_name");
    },
    profile_picture_url() {
      return (
        this.$store.state.profile_picture_url ||
        localStorage.getItem("profile_picture_url")
      );
    },
    user_location_name() {
      return (
        localStorage.getItem("location_name")
      );
    },
    //   user_loc_id() {
    //     return this.$store.state.user_loc_id || localStorage.getItem('user_loc_id');
    //   },
    user_email() {
      return this.$store.state.email || localStorage.getItem("email");
    },
    dmv_logo() {
      return this.$store.state.dmv_logo || localStorage.getItem("dmv_logo");
    },
    //   dmv_bg_logo() { // for written exam seal background
    //     return this.$store.state.dmv_bg_logo || localStorage.getItem('dmv_bg_logo');
    //   },
    client_name() {
      return this.$store.state.user.client
        ? this.$store.state.user.client.client_name
        : "";
    },
    //  user_permissions() {
    //     return this.$store.state.user_permissions ? this.$store.state.user_permissions : [];
    //   },
    //   // return station
    //   station_id() {
    //     return this.$store.state.station_id || localStorage.getItem('station_id');
    //   },
    station_cust_exam_id() {
      return (
        this.$store.state.station_cust_exam_id ||
        localStorage.getItem("station_cust_exam_id")
      );
    },
    station_exams_id() {
      return (
        this.$store.state.station_exams_id ||
        localStorage.getItem("station_exams_id")
      );
    },

    station_lang_id() {
      return (
        this.$store.state.station_lang_id ||
        localStorage.getItem("station_lang_id")
      );
    },

    //   user_role() {
    //     return this.$store.state.user_role || localStorage.getItem('user_role');
    //   },
    user_role() {
      return localStorage.getItem("user_role");
    },
    user_location_id() {
      return localStorage.getItem("location_id");
    },

    user_permissions() {
      return  this.$store.state.user_permissions
      ? this.$store.state.user_permissions
      : [];
    },
    UserID() {
      if(this.$store.state.user_id) return this.$store.state.user_id;
       else return 0;
    },
    client_name_school() {
      return localStorage.getItem("client_name")
        ? localStorage.getItem("client_name")
        : "no school name found";
    },
  },
};
