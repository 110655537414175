import { createRouter, createWebHistory } from "vue-router";

// // Function to check if the user is logged in
// function isLoggedIn() {
//     return localStorage.getItem("clientaccesstoken") || localStorage.getItem("accesstoken");
// }
// check if System user is not logged in
function guardSystemRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("accesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/system-admin/login"); // go to '/login';
    }
}

// check if the user is logged in
function is_authenticated(to, from, next) {
    var isAuthenticated =
        localStorage.getItem("accesstoken") ||
        localStorage.getItem("clientaccesstoken") ||
        localStorage.getItem("dmvaccesstoken")||
        localStorage.getItem("customeraccesstoken") ||
        localStorage.getItem("educationalaccesstoken");

    if (!isAuthenticated) {
        next();
    } else {
        const redirectRoute = localStorage.getItem("accesstoken") ?
            "/system/dashboard" :
            localStorage.getItem("customeraccesstoken") ?
            "/test-screen/splash-wait-2" :  
            localStorage.getItem("dmvaccesstoken") ? "/dmv/dashboard" :  
            localStorage.getItem("educationalaccesstoken") ? "/educational-institute" : 
            "/proctor-dashboard";
        next(redirectRoute);
    }
}

function guardClientRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("clientaccesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/proctor/login"); // go to '/login';
    }
}

function guardDMVClientRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("dmvaccesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/dmv/login"); // go to '/login';
    }
}

function guardCustomerRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("customeraccesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/proctor/login"); // go to '/login';
    }
}

function guardEducationalInstituteRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("educationalaccesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/educational-institute/login"); // go to '/login';
    }
}

function guardStudentDashboardRoute(to, from, next) {
    var isAuthenticated = false;
    if (localStorage.getItem("studentaccesstoken")) isAuthenticated = true;
    else isAuthenticated = false;
    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/educational-institute/login"); // go to '/login';
    }
}

const routes = [
    // InteRoad Routes

    //****************************************************************** //
    //                 //  System Dashboard Routes
    //****************************************************************** //
    {
        path: "/system-admin/login",
        name: "SystemLoginComponent",
        component: () =>
            import ("@/components/SystemDashboard/LogIn.vue"),
        beforeEnter: is_authenticated,
    },
    {
        path: "/system",
        name: "SystemDashbaord",
        component: () =>
            import ("@/components/SystemDashboard/SystemDashboardSkeleton.vue"),
        beforeEnter: guardSystemRoute,
        children: [{
                path: "/system/dashboard",
                name: "SystemDashbaordCom",
                component: () =>
                    import ("@/components/SystemDashboard/DashboardComponent.vue"),
            },

            {
                path: "/system/proctors",
                name: "proctorDashbaord",
                component: () =>
                    import ("@/components/SystemDashboard/ProctorsComponent.vue"),
            },
            {
                path: "/system/time-slot",
                name: "TimeSlot",
                component: () =>
                    import ("@/components/SystemDashboard/TimeSlot.vue"),
            },
            {
                path: "/system/rooms",
                name: "RoomComponent",
                component: () =>
                    import ("@/components/SystemDashboard/RoomsComponent.vue"),
            },
            {
                path: "/system/customers",
                name: "CustomersComponent",
                component: () =>
                    import ("@/components/SystemDashboard/CustomersComponent.vue"),
            },
            {
                path: "/system/settings",
                name: "settingsComponent",
                component: () =>
                    import ("@/components/SystemDashboard/SettingsCompnent.vue"),
            },
        ],
    },
    //****************************************************************** //
    //                 //  System Dashboard Routes End
    //****************************************************************** //

    //****************************************************************** //
    //                 //  Proctor Dashboard Routes
    //****************************************************************** //
    {
        path: "/proctor/login",
        name: "loginComponent",
        component: () =>
            import ("@/components/LogIn.vue"),
        beforeEnter: is_authenticated,
    },
    {
        path: "/proctor/signup",
        name: "signupComponent",
        component: () =>
            import ("@/components/SignUp.vue"),
        beforeEnter: is_authenticated,
    },

    {
        path: "/proctor-dashboard",
        name: "DashboardComponents",
        component: () =>
            import ("@/components/ProctorDashboardSkeleton.vue"),
        beforeEnter: guardClientRoute,
        children: [{
                path: "",
                name: "DashboardComp",
                component: () =>
                    import ("@/components/ProctorDashboardComponent.vue"),
            },
            {
                path: "set-availability",
                name: "SetAvailabilityComponent",
                component: () =>
                    import ("@/components/SetAvailability.vue"),
            },
            {
                path: "proctor-training",
                name: "ProctorTrainingComponent",
                component: () =>
                    import ("@/components/ProctorTraining.vue"),
            },
            {
                path: "exams",
                name: "ExamsComponent",
                component: () =>
                    import ("@/components/ExamsComponent.vue"),
            },
            {
                path: "proctor-wallet",
                name: "ProctorWalletComponent",
                component: () =>
                    import ("@/components/ProctorWallet.vue"),
            },
        ],
    },

    //************************Proctoring-room **************************/
    {
        path: "/Candidate",
        name: "CandidateComponents",
        component: () =>
            import ("@/components/Candidate/CandidateOnBoarding.vue"),
        beforeEnter: guardClientRoute,
        children: [{
            path: "Candidate-onboarding",
            name: "CandidateOnboardingComponent",
            component: () =>
                import ("@/components/Candidate/CandidateOnBoarding.vue"),
        }, ],
    },

    //************************Proctoring-room **************************/

    //****************************************************************** //
    //                 //  Proctor Dashboard Routes End
    //****************************************************************** //

    //****************************************************************** //
    //                 // Scheduling Routes
    //****************************************************************** //
    {
        path: "/scheduling",
        name: "ScheduleComponents",
        component: () =>
            import ("@/components/ScheduleTest/TestType.vue"),
        beforeEnter: is_authenticated,
        children: [{
            path: "schedule-exam",
            name: "ScheduleComponent",
            component: () =>
                import ("@/components/ScheduleTest/TestType.vue"),
        }, ],
    },
    //****************************************************************** //
    //                 // Scheduling Routes End
    //****************************************************************** //

    //****************************************************************** //
    //                 // customer Routes
    //****************************************************************** //
    // {
    //     path: "/customer",
    //     name: "CustomerComponents",
    //     component: () =>
    //         import ("@/components/Customer/CustomerOnboarding.vue"),
    //     children: [{
    //         path: "customer-onboarding",
    //         name: "CustomerOnboardingComponent",
    //         component: () =>
    //             import ("@/components/Customer/CustomerOnboarding.vue"),
    //     }, ],
    // },

    {
        path: "/customer",
        name: "CustomerComponents",
        // component: () =>
            // import ("@/components/Customer/CustomerOnboarding.vue")
        children: [
            {
            path: "customer-onboarding",
            name: "CustomerOnboardingComponent",
            component: () =>
                import ("@/components/Customer/CustomerOnboarding.vue"),
        }, 
        {
            path: "web-onboarding",
            name: "CustomerWebOnboarding",
            component: () => import("@/components/Customer/CustomerWebOnboarding.vue"),
        },
        {
            path: "web-onboarding-automated",
            name: "CustomerWebOnboardingAuto",
            component: () => import("@/components/Customer/CustomerWebOnboardingAuto.vue"),
        },
        {
            path: "edu-automated",
            name: "eduCustomerWebOnboardingAuto",
            component: () => import("@/components/Customer/EduCustomerWebOnboardingAuto.vue"),
        },
        {
            path: "edu-automated-desktop",
            name: "eduCustomerWebOnboardingAutoDesktop",
            component: () => import("@/components/Customer/EduCustomerWebOnboardingAutoDesk.vue"),
        },
        {
            path: "edu-Proctored",
            name: "eduCustomerWebOnboardingProctored",
            component: () => import("@/components/Customer/EduCustomerWebOnboardingProctored.vue"),
        },
        {
            path: "edu-Proctored-desktop",
            name: "eduCustomerWebOnboardingProctoredDesktop",
            component: () => import("@/components/Customer/EduCustomerWebOnboardingProctoredDesktop.vue"),
        }

    ],  
    },
    //************************Test Screen Routes **************************/
    {
        path: "/test-screen",
        name: "testScreensComp",
        component: () =>
            import ("@/components/TestScreens/TestScreenSkeleton.vue"),
        beforeEnter: guardCustomerRoute,
        children: [{
                path: "splash-wait-1",
                name: "splashscreenwait1Comp",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "splashscreenwait2",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "select-station",
                component: () =>
                    import ("@/components/TestScreens/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "authComp",
                component: () =>
                    import ("@/components/TestScreens/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "instructionsComp",
                component: () =>
                    import ("@/components/TestScreens/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "testInfoComp",
                component: () =>
                    import ("@/components/TestScreens/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "sampleTestComp",
                component: () =>
                    import ("@/components/TestScreens/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "questionsScreenComp",
                component: () =>
                    import ("@/components/TestScreens/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "testdetailScreenComp",
                component: () =>
                    import ("@/components/TestScreens/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "testquestiondetailScreen",
                component: () =>
                    import ("@/components/TestScreens/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "cancelOngoingExam",
                component: () =>
                    import ("@/components/TestScreens/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStep",
                component: () =>
                    import ("@/components/TestScreens/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "NextStepCanceled",
                component: () =>
                    import ("@/components/TestScreens/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },

    {
        path: "/test-screen-web",
        name: "testScreensCompWeb",
        component: () =>
            import ("@/components/TestScreens/TestScreenSkeletonWeb.vue"),
        beforeEnter: guardCustomerRoute,
        children: [{
                path: "splash-wait-1",
                name: "splashscreenwait1Comp",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "splashscreenwaitWeb2",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWaitweb2.vue"),
            },
          
            {
                path: "select-station",
                name: "select-station-web",
                component: () =>
                    import ("@/components/TestScreens/SelectStation.vue"),
            },
            {
                path: "auth-web",
                name: "authCompWeb",
                component: () =>
                    import ("@/components/TestScreens/AuthCompWeb.vue"),
            },
            {
                path: "instructions",
                name: "instructionsCompWeb",
                component: () =>
                    import ("@/components/TestScreens/InstructionsCompWeb.vue"),
            },
            {
                path: "testinfo",
                name: "testInfoCompWeb",
                component: () =>
                    import ("@/components/TestScreens/TestInfoWeb.vue"),
            },
            {
                path: "sampletest",
                name: "sampleTestCompWeb",
                component: () =>
                    import ("@/components/TestScreens/SampleTestWeb.vue"),
            },
            {
                path: "questions-screen",
                name: "questionsScreenCompWeb",
                component: () =>
                    import ("@/components/TestScreens/QuestionsScreenWeb.vue"),
            },
            {
                path: "test-result",
                name: "testdetailScreenCompWeb",
                component: () =>
                    import ("@/components/TestScreens/TestResultWeb.vue"),
            },
            {
                path: "review-questions",
                name: "testquestiondetailScreenWeb",
                component: () =>
                    import ("@/components/TestScreens/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "cancelOngoingExamWeb",
                component: () =>
                    import ("@/components/TestScreens/EndExamAlertWeb.vue"),
            },
            {
                path: "next-step",
                name: "NextStepWeb",
                component: () =>
                    import ("@/components/TestScreens/NextStepWeb.vue"),
            },
            {
                path: "next-step/canceled",
                name: "NextStepCanceledWeb",
                component: () =>
                    import ("@/components/TestScreens/NextStepCanceledWeb.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },

    {
        path: "/test-screen-webAuto",
        name: "testScreensCompWebAuto",
        component: () =>
            import ("@/components/TestScreens/TestScreenSkeletonWebAuto.vue"),
        beforeEnter: guardCustomerRoute,
        children: [{
                path: "splash-wait-1",
                name: "splashscreenwait1CompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWait1WbAuto.vue"),
            },
            {
                path: "splash-wait-2",
                name: "splashscreenwaitWeb2Auto",
                component: () =>
                    import ("@/components/TestScreens/SplashScreenWaitweb2Auto.vue"),
            },
            {
                path: "select-station",
                name: "select-station-web-auto",
                component: () =>
                    import ("@/components/TestScreens/SelectStationWebAuto.vue"),
            },
            {
                path: "auth-web",
                name: "authCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/AuthCompWebAuto.vue"),
            },
            {
                path: "instructions",
                name: "instructionsCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/InstructionsCompWebAuto.vue"),
            },
            {
                path: "testinfo",
                name: "testInfoCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/TestInfoWebAuto.vue"),
            },
            {
                path: "sampletest",
                name: "sampleTestCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/SampleTestWebAuto.vue"),
            },
            {
                path: "questions-screen",
                name: "questionsScreenCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/QuestionsScreenWebAuto.vue"),
            },
            {
                path: "test-result",
                name: "testdetailScreenCompWebAuto",
                component: () =>
                    import ("@/components/TestScreens/TestResultWebAuto.vue"),
            },
            {
                path: "review-questions",
                name: "testquestiondetailScreenWebAuto",
                component: () =>
                    import ("@/components/TestScreens/ReviewExamWebAuto.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "cancelOngoingExamWebAuto",
                component: () =>
                    import ("@/components/TestScreens/EndExamAlertWebAuto.vue"),
            },
            {
                path: "next-step",
                name: "NextStepWebAuto",
                component: () =>
                    import ("@/components/TestScreens/NextStepWebAuto.vue"),
            },
            {
                path: "next-step/canceled",
                name: "NextStepCanceledWebAuto",
                component: () =>
                    import ("@/components/TestScreens/NextStepCanceledWebAuto.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },
    //************************Test Screen Routes **************************/
    //****************************************************************** //
    //                 // customer Routes
    //****************************************************************** //


    //                 // DMV Routes
    //****************************************************************** //

    {
        path: "/dmv/login",
        name: "dmvLoginComponent",
        component: () =>
            import ("@/components/DMV/LogIn.vue"),
        beforeEnter: is_authenticated,
    },
    {
        path: "/dmv",
        name: "DMVDashboard",
        component: () =>
            import ("@/components/DMV/DmvDashboadSkeleton.vue"),
        beforeEnter: guardDMVClientRoute,
        children: [{
                path: "dashboard",
                name: "DmvDashboardComp",
                component: () =>
                    import ("@/components/DMV/DmvDashboardComponen.vue"),
            },
            {
                path: "customers",
                name: "CustomersDashboardComp",
                component: () =>
                    import ("@/components/DMV/CustomersDashboard.vue"),
            },

        ],
    },


    //                 // DMV Routes Ends
    //****************************************************************** //

    //                 // Landing Page Routes
    //****************************************************************** //
    {
        path: "/",
        name: "landingPage",
        component: () =>
            import ("@/components/Website/HomePage/HomeMain.vue"),
    },
    {
        path: "/about",
        name: "AboutPage",
        component: () =>
            import ("@/components/Website/AboutPage/AboutMain.vue"),
    },
    {
        path: "/features",
        name: "FeaturesPage",
        component: () =>
            import ("@/components/Website/FeaturesPage/FeaturesMain.vue"),
    },
    {
        path: "/contact",
        name: "ContactPage",
        component: () =>
            import ("@/components/Website/ContactPage/ContactMain.vue"),
    },

    
    //                 // Landing Page Routes Ends
    //****************************************************************** //

        
    //                 // Educational institute Routes
    //****************************************************************** //

    {
        path: "/educational-institute/login",
        name: "eduLoginComponent",
        component: () =>
            import ("@/components/EducationalInstitute/Auth/InstituteSignin/LoginMainpage.vue"),
        beforeEnter: is_authenticated,
    },
    {
        path: "/educational-institute/reset-password/:token/:email",
        name: "ResetPassword",
        beforeEnter: is_authenticated,
        component: () =>
            import ("@/components/EducationalInstitute/Auth/InstituteSignin/ResetPassword.vue"),
    },
    {
        path: "/educational-institute/recover-password",
        name: "RecoverPassword",
        beforeEnter: is_authenticated,
        component: () =>
            import ("@/components/EducationalInstitute/Auth/InstituteSignin/RecoverPassword.vue"),
    },
    {
        path: "/educational-institute/signup",
        name: "eduSignupComponent",
        component: () =>
            import ("@/components/EducationalInstitute/Auth/SignUp.vue"),
        beforeEnter: is_authenticated,
    },

    {
        path: "/educational-institute",
        name: "eduDashboardComponents",
        component: () =>
            import ("@/components/EducationalInstitute/Dashboard/EduInstituteMainLayout.vue"),
        beforeEnter: guardEducationalInstituteRoute,
        children: [
            {
                path: "",
                name: "eduDashboardComp",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/eduDasboard.vue"),
            },
            {
                path: "manage-branch",
                name: "ManageBranch",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Management/Braches/BranchManagement.vue"),
            },
            {
                path: "manage-roles",
                name: "ManageRoles",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Management/Roles/RolesManagement.vue"),
            },
            {
                path: "manage-user",
                name: "ManageUser",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Management/User/UserManagement.vue"),
            },
            {
                path: "manage-courses",
                name: "ManageCourses",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Management/Courses/CoursesManagement.vue"),
            },
            {
                path: "question-category",
                name: "QuestionCategory",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Questions/QuestionCategory/QuestionCategory.vue"),
            },
            {
                path: "question-questions",
                name: "QuestionQuestions",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Questions/QuestionQuestions/QuestionQuestions.vue"),
            },
            {
                path: "students-student",
                name: "StudentsStudent",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/Students/Students.vue"),
            },
            {
                path: "students-exams",
                name: "StudentsExams",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/Exams/Exams.vue"),
            },
            {
                path: "students-exam-assigned",
                name: "ExamAssigned",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/ExamAssigned/ExamAssigned.vue"),
            },
            {
                path: "exam/students/detail",
                name: "CompletedCustomerRecordComp",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/Students/CompletedCustomerRecordCom.vue"),
            },
            {
                path: "exam/students/detail/view",
                name: "PracticeCustomerDetailsComp",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/Students/CustomerDetailsComp.vue"),
            },
            {
                path: "exam/students/detail/result",
                name: "customerresultComp",
                component: () =>
                    import ("@/components/EducationalInstitute/Dashboard/Students/Students/CustomerResultSkeleton.vue"),
            },
            {
                path: "settings",
                name: "settings",
                component: () =>
                import("@/components//EducationalInstitute/BODashboardComp.vue"),
            },
        ],
    },

    //                 // Educational institute Routes Ends
    //****************************************************************** //
   
    //                 //Student Dashboard Routes
    //****************************************************************** //


    {
        path: "/student-dashboard",
        name: "StudentDashboard",
        component: () =>
            import ("@/components/StudentDashboard/MainLayout/StudMainLayout.vue"),
        beforeEnter: guardStudentDashboardRoute,
        children: [
            {
                path: "",
                name: "studDashboardComp",
                component: () =>
                    import ("@/components/StudentDashboard/Dashboard/studDashboard.vue"),
            },
            {
                path: "courses",
                name: "studCoursesComp",
                component: () =>
                    import ("@/components/StudentDashboard/CoursesStud/coursesComp.vue"),
            },
            {
                path: "exams-previous",
                name: "PreviousExams",
                component: () =>
                    import ("@/components/StudentDashboard/Exams/PreviousExams.vue"),
            },
            {
                path: "exams-upcoming",
                name: "UpcomingExams",
                component: () =>
                    import ("@/components/StudentDashboard/Exams/UpcomingExams.vue"),
            },
            {
                path: "exams-available",
                name: "AvailableExams",
                component: () =>
                    import ("@/components/StudentDashboard/Exams/AvailableExams.vue"),
            },
            {
                path: "profile",
                name: "StudentProfile",
                component: () =>
                    import ("@/components/StudentDashboard/Account/ProfileComp.vue"),
            },
            {
                path: "password",
                name: "PasswordComponent",
                component: () =>
                    import ("@/components/StudentDashboard/Account/PasswordComp.vue"),
            },
            {
                path: "exam-detail",
                name: "ExamDetail",
                component: () =>
                    import ("@/components/StudentDashboard/Exams/ViewExamDetail.vue"),
            },

        ],
    },
    //************************Test Screen Routes **************************/
    {
        path: "/student/test-screen",
        name: "automatedTestScreensComp",
        component: () =>
        import("@/components/StudentDashboard/TestScreens/TestScreenSkeleton.vue"),
        children: [
            {
                path: "splash-wait-1",
                name: "automatedSplashscreenwait1Comp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "automatedSplashscreenwait2",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "automated-select-station",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "automatedAuthComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "automatedInstructionsComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "automatedTestInfoComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "automatedSampleTestComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "automatedQuestionsScreenComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "automatedTestdetailScreenComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "automatedTestquestiondetailScreen",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "automatedCancelOngoingExam",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStep",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "automatedNextStepCanceled",
                component: () =>
                import("@/components/StudentDashboard/TestScreens/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },


    {
        path: "/student/test-screen-automated",
        name: "automatedTestScreensAutomatedComp",
        component: () =>
        import("@/components/StudentDashboard/TestScreensAutomated/TestScreenSkeleton.vue"),
        children: [
            {
                path: "splash-wait-1",
                name: "automatedSplashscreenwait1AutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "automatedSplashscreenwait2Automated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "automated-select-station-Automated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "automatedAuthAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "automatedInstructionsAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "automatedTestInfoAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "automatedSampleTestAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "automatedQuestionsScreenAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "automatedTestdetailScreenAutomatedComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "automatedTestquestiondetailScreenAutomated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "automatedCancelOngoingExamAutomated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStepAutomated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "automatedNextStepCanceledAutomated",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomated/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },

    {
        path: "/student/test-screen-Proctored",
        name: "automatedTestScreensProctoredComp",
        component: () =>
        import("@/components/StudentDashboard/TestScreensProctored/TestScreenSkeleton.vue"),
        children: [
            {
                path: "splash-wait-1",
                name: "automatedSplashscreenwait1ProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "automatedSplashscreenwait2Proctored",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "automated-select-station-Proctored",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "automatedAuthProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "automatedInstructionsProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "automatedTestInfoProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "automatedSampleTestProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "automatedQuestionsScreenProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "automatedTestdetailScreenProctoredComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "automatedTestquestiondetailScreenProctored",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "automatedCancelOngoingExamProctored",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStepProctored",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "ProctoredNextStepCanceled",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctored/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },

    {
        path: "/student/test-screen-automated-desktop",
        name: "automatedTestScreensAutomatedDesktop",
        component: () =>
        import("@/components/StudentDashboard/TestScreensAutomatedDesktop/TestScreenSkeleton.vue"),
        children: [
            {
                path: "splash-wait-1",
                name: "automatedSplashscreenwait1AutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "automatedSplashscreenwait2AutomatedDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "automated-select-station-Automated-Desktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "automatedAuthAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "automatedInstructionsAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "automatedTestInfoAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "automatedSampleTestAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "automatedQuestionsScreenAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "automatedTestdetailScreenAutomatedDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "automatedTestquestiondetailScreenAutomatedDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "automatedCancelOngoingExamAutomatedDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStepAutomatedDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "automatedNextStepCanceledAutomatedDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensAutomatedDesktop/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },

    {
        path: "/student/test-screen-Proctored-desktop",
        name: "automatedTestScreensProctoredDesktopComp",
        component: () =>
        import("@/components/StudentDashboard/TestScreensProctoredDesktop/TestScreenSkeleton.vue"),
        children: [
            {
                path: "splash-wait-1",
                name: "automatedSplashscreenwait1ProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/SplashScreenWait1.vue"),
            },
            {
                path: "splash-wait-2",
                name: "automatedSplashscreenwait2ProctoredDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/SplashScreenWait2.vue"),
            },
            {
                path: "select-station",
                name: "automated-select-station-ProctoredDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/SelectStation.vue"),
            },
            {
                path: "auth",
                name: "automatedAuthProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/AuthComp.vue"),
            },
            {
                path: "instructions",
                name: "automatedInstructionsProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/InstructionsComp.vue"),
            },
            {
                path: "testinfo",
                name: "automatedTestInfoProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/TestInfo.vue"),
            },
            {
                path: "sampletest",
                name: "automatedSampleTestProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/SampleTest.vue"),
            },

            {
                path: "questions-screen",
                name: "automatedQuestionsScreenProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/QuestionsScreen.vue"),
            },
            {
                path: "test-result",
                name: "automatedTestdetailScreenProctoredDesktopComp",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/TestResult.vue"),
            },
            {
                path: "review-questions",
                name: "automatedTestquestiondetailScreenProctoredDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/ReviewExam.vue"),
            },
            {
                path: "cancel-ongoing-exam",
                name: "automatedCancelOngoingExamProctoredDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/EndExamAlert.vue"),
            },
            {
                path: "next-step",
                name: "NextStepProctoredDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/NextStep.vue"),
            },
            {
                path: "next-step/canceled",
                name: "ProctoredNextStepCanceledDesktop",
                component: () =>
                import("@/components/StudentDashboard/TestScreensProctoredDesktop/NextStepCanceled.vue"),
            },
            // {
            //     path: "print/:exams_id/:exam_language",
            //     name: "printComp",
            //     component: () =>
            //         import ("@/components/FrontOffice/PrintScreens/PrintCom.vue"),
            // },
        ],
    },
    //                 // Student Dashboard Routes Ends
    //****************************************************************** //
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
